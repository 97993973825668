import { render, staticRenderFns } from "./returnPolicy.vue?vue&type=template&id=56e3cfda&scoped=true"
import script from "./returnPolicy.vue?vue&type=script&lang=js"
export * from "./returnPolicy.vue?vue&type=script&lang=js"
import style0 from "./returnPolicy.vue?vue&type=style&index=0&id=56e3cfda&prod&lang=css"
import style1 from "./returnPolicy.vue?vue&type=style&index=1&id=56e3cfda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e3cfda",
  null
  
)

export default component.exports