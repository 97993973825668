<template>
    <div class="returnPolicy">
        <div class="returnPolicy-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Return Policy</h5>
        </div>
        <div class="returnPolicy-main">
            <div class="text">
                <h5>RETURNS OF ONLINE PURCHASES</h5>
                <span>Most of our garments purchased are eligible for returns within 45 days after the purchase date with full credit to the original payment method. Customers may also chose to credit their Soyego wallet with the full price of the returned item(s).</span>
                <span>Bodysuits, lingerie, underwear, jewelry and beauty products are not eligible for return. Personal care products (including cosmetic products) cannot be returned if the hygiene seal is broken. Certain products with non-returnable marks (shown in product description) are not returnable.</span>
            </div>
            <div class="text">
                <h5>IMPORTANT NOTICE</h5>
                <p>Shipping and handling costs are not refundable, a local warehouse address will be provided for return items and customers are expected to pay for return shipping. </p>
                <p>You can only return items that are unworn, unwashed and undamaged. The original tags/packaging attached should be intact. Do not return the products to the sender’s address, we have different warehouses for return products.</p>
                <p>Refunds will be processed within 7 days after we receive your package.</p>
            </div>
            <div class="text">
                <h5>HOW DO I MAKE A RETURN?</h5>
                <p>1. Sign in your Soyego account.</p>
                <p>2. Find the order in My orders, click “Customer Service”</p>
                <p>3. Select the item(s) you would like to return, select the reason, and submit.</p>
                <p>4. A return address will be shown after we process the return.</p>
                <p>5. Send the items to the provided address.</p>
            </div>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
.returnPolicy {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .returnPolicy-title {
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        .back {
            position: absolute;
            display: flex;
            align-items: center;
            cursor: pointer;
            left: 20px;

            span {
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: Regular;
                color: #330000;
                padding-left: 6px;
            }
        }

        h5 {
            font-size: 30px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            color: #330000;
        }
    }

    .returnPolicy-main{
        width: calc(100% - 40px);
        padding: 10px 20px 20px;
        display: flex;
        flex-direction: column;
        
        .text{
            width: 100%;
            padding-top: 60px;
            display: flex;
            flex-direction: column;

            h5{
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: bold;
                color: #330000;
                padding-bottom: 35px;
            }
            span{
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: normal;
                color: #330000;
            }
            span:last-child{
                padding-top: 35px;
            }
            p{
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: normal;
                color: #330000;
            }
        }
    }
}
</style>

<script>
export default {
    name: "returnPolicy",
    components: {

    },
    data: () => {
        return {
            
        }
    },
    created() { },
    mounted() { },
    methods: {
        backClick() {
            this.$router.go(-1);
        }
    }
}
</script>
